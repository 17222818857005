<div class="how-we-help-container container">
  <div class="heading">
    <img src="assets/logo_VO_in_circle.png" alt="logo">
    <h1>Jak vám pomůžeme</h1>
  </div>
  <div class="line"></div>
  <div class="text">
    <p>Vracím Operák je služba určena jednotlivcům i firmám, kteří mají vozidlo na operativní leasing a blíží se
      termín ukončení operativního leasingu. Leasingové firmy jsou striktní ke stavu vrácených vozidel a tolerují 
      pouze drobná poškození. Odřená kola, důlky z parkoviště, nadměrně opotřebený lak, znečištěný či poškozený
      interiér jsou důvody, kvůli kterým leasingové firmy účtují pokuty či spoluúčasti na škodách.
    </p>
    <p>
      V praxi to funguje tak, že vozidlo přistavíte k nám, my odstraníme veškeré poškození způsobené provozem,
      a pomůžeme Vám tak ušetřit 2/3 na smluvních pokutách.
    </p>
    <h3>Tím pádem je navracení snadné!</h3>
    <p>
      Pro firmy s vozovým parkem vzniká velká úspora peněz a času, protože jsme schopni celou operaci s navrácením
      vozovéhoparku přizpůsobit na míru dané firmě.
    </p>
  </div>
  <div class="services">
    <div class="service">
      <img src="assets/laky.png" alt="service-icon">
      <h4>Lakování a oprava laku</h4>
    </div>
    <div class="service">
      <img src="assets/dolicky.png" alt="service-icon">
      <h4>Oprava deformace metodou PDR</h4>
    </div>
    <div class="service">
      <img src="assets/sedacka.png" alt="service-icon">
      <h4>čištění a renovace interiéru</h4>
    </div>
    <div class="service">
      <img src="assets/kolo.png" alt="service-icon">
      <h4>renovace kol z lehkých slitin</h4>
    </div>
  </div>
</div>
