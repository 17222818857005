<div class="about-container container">
  <div class="about">
    <div class="image-wrapper">
      <img src="assets/about-photo.png" alt="illustration">
    </div>
    <div class="text">
      <div class="heading">
        <h1>O nás</h1>
      </div>
      <div class="line"></div>
      <p>Jme spečnost zabývající se estetickými opravami vozů a to především opravami deformací karoserie metodou PDR
        (důlky z parkoviště, krupobytí a vandalismus). Dále pak strojní korekcí laku, čistěním a renovací interierů,
        lakováním a v neposlední řade renovací kol z lehkých slitin, Díky našim zkušenostem a rozsáhlému portfoliu 
        služeb jsme pro Vás připravili službu VracímOperák.cz</p>
    </div>
  </div>
  <div class="stats-in-circle">
    <div class="stat">
      <img src="assets/usetrene-naklady.png" alt="statistic-icon">
      <h4>ušetřených nákladů</h4>
    </div>
    <div class="stat">
      <img src="assets/vracene-vozy.png" alt="statistic-icon">
      <h4>úspěšně vrácených vozů</h4>
    </div>
    <div class="stat">
      <img src="assets/vracene-vozy-2.png" alt="statistic-icon">
      <h4>úspěšně vrácených vozů</h4>
    </div>
    <div class="stat">
      <img src="assets/percentage.png" alt="statistic-icon">
      <h4>spokojených zákazníků</h4>
    </div>
  </div>
</div>
