import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {gsap, Power3} from "gsap/all";
import {filter, switchMap} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngAfterViewInit() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      switchMap(() => this.route.fragment)
    ).subscribe(frag => {
      if (typeof frag === 'string') {
        const id = '#' + frag.trim();

        if (id.length > 1) {
          const fragElement = document.getElementById(frag);

          if (fragElement !== null) {
            /*
            Variables:
            - 0.81: Minimal scroll duration
            - 2000: If smaller, scroll duration is longer; If bigger, scroll duration is shorter
            - Power3.easeOut: Easing function from GSAP library
            */

            gsap.to(window, {
              duration: Math.max(0.81, Math.log(Math.abs(fragElement.offsetTop - window.scrollY) / 2000)),
              scrollTo: id,
              ease: Power3.easeOut
            });
          }
        }
      }
    });
  }

}
