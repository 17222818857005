<div class="faq-container container">
  <div class="heading">
    <h1>faq</h1>
  </div>
  <div class="line"></div>

  <div class="faq-items">
    <div class="item" *ngFor="let item of faq">
      <div class="question" [class.opened]="item.isOpened" (click)="toggleQuestionOpen(item)">
        <div class="question-text">
          <h4>Q: </h4>
          <h4>{{ item.question }}</h4>
        </div>
        <button>
          <i class="fal fa-minus-circle" *ngIf="item.isOpened == true"></i>
          <i class="fal fa-plus-circle" *ngIf="item.isOpened == false"></i>
        </button>
      </div>

      <div class="answer" *ngIf="item.isOpened">
        <h4>A: </h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur.</p>
      </div>
    </div>
  </div>
</div>
