import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if ('has' in request.headers && request.headers.has('X-Skip-Error-Handler-Interceptor')) {
      return next.handle(request.clone({
        headers: request.headers.delete('X-Skip-Error-Handler-Interceptor')
      }));
    }

    return next.handle(request)
      .pipe(
        catchError(err => {
          this.router.navigate([ '/http-error' ], {
            state: {
              isError: true
            }
          });

          return throwError(err);
        })
      );
  }
}
