<ng-template #menu>
  <nav class="header-menu" [class.menu-opened]="mobileMenuState === MENU_OPENED">
    <ul class="menu-list">
      <li *ngFor="let item of menuItems" class="menu-item">
        <a
          [routerLink]="[ item.url ]" [fragment]="item?.fragment" (click)="setMobileMenuState(true)"
        >{{ item.title }}</a>
      </li>
    </ul>
  </nav>
</ng-template>

<div class="top-stripe">
  <div class="text-line">
    <div class="mail">
      <i class="fas fa-envelope"></i>
      <a href="mailto:">info@vracimoperak.cz</a>
    </div>
    <div class="phone">
      <i class="fas fa-phone-alt"></i>
      <a href="tel:">+420 608 775 775</a>
    </div>
  </div>
</div>
<header id="header" [class.solid]="isScrolled">
  <a routerLink="/">
    <img id="logo" alt="logo"
         [src]="((screen.mobile | async) ? 'assets/logo_VO_text.png' : 'assets/logo_VO_text.png')">
  </a>

  <button
    *ngIf="!(screen.desktop | async)"
    class="hamburger hamburger--spin"
    type="button"
    [class.is-active]="mobileMenuState === MENU_OPENED"
    (click)="setMobileMenuState(true)"
  >
		<span class="hamburger-box">
			<span class="hamburger-inner"></span>
		</span>
  </button>

  <ng-container *ngIf="screen.desktop | async">
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </ng-container>
</header>

<ng-container *ngIf="!(screen.desktop | async)">
  <ng-container *ngTemplateOutlet="menu"></ng-container>
</ng-container>
