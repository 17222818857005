import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  desktop3XL = new BehaviorSubject(false);
  desktopXXL = new BehaviorSubject(false);
  desktopXL = new BehaviorSubject(false);
  desktopL = new BehaviorSubject(false);
  desktop = new BehaviorSubject(false);
  tablet = new BehaviorSubject(false);
  mobile = new BehaviorSubject(false);

  maxWidth900 = new BehaviorSubject(false);

  constructor() {
    this.setSizes();

    window.addEventListener('resize', this.setSizes.bind(this));
  }

  setSizes(): void {
    const width = window.innerWidth;

    this.mobile.next(width <= 425);
    this.tablet.next(width > 425 && width <= 768);
    this.desktop.next(width > 768);
    this.desktopL.next(width >= 1024);
    this.desktopXL.next(width >= 1440);
    this.desktopXXL.next(width >= 1920);
    this.desktop3XL.next(width >= 2560);
  }
}
