import {Component, HostListener, OnInit} from '@angular/core';
import {ScreenService} from "../../shared/services/screen.service";
import {config} from "../../config";

export const MENU_OPENED = 'opened';
export const MENU_CLOSED = 'closed';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  config = config;

  menuItems = config.menu;

  mobileMenuState: string;

  isScrolled = false;

  // Menu states
  MENU_OPENED = MENU_OPENED;
  MENU_CLOSED = MENU_CLOSED;

  constructor(public screen: ScreenService) { }

  @HostListener('window:resize')
  onWindowResize(): void {
    if (!this.screen.desktop.getValue()) {
      this.setMobileMenuState(false);
    }
  }

  @HostListener('window:scroll')
  onScroll(): void {
    this.isScrolled = window.scrollY > 0;
  }

  ngOnInit(): void {
    this.setMobileMenuState(true);
  }

  setMobileMenuState(toggle: boolean): void {
    this.mobileMenuState = this.mobileMenuState === MENU_CLOSED && toggle ? MENU_OPENED : MENU_CLOSED;
  }

}

