<div class="landing-container">
  <div class="image-wrapper">
    <div class="text-overlay">
      <h3>Snadno jezdit, snadno vracet.</h3>
      <h1>Snadno jezdit, snadno vracet.</h1>
      <p>Pomůžeme Vás ušetřit čas i peníze při navrácení vozu odstraněním drobných kosmetických vad. </p>
      <div class="buttons">
        <a [routerLink]="['/']" [fragment]="'references'">
          <button type="button" class="see-more">Zjistit více</button>
        </a>
        <a [routerLink]="['/']" [fragment]="'references'">
          <button type="button" class="how-we-help">Komu jsme pomohli</button>
        </a>
      </div>
    </div>
    <img src="assets/landing-photo.png" alt="landing_photo">
  </div>
</div>
