import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactService} from "../../shared/services/contact.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  FORM_UNSENT = 0;
  FORM_SUBMITTED = 1;
  FORM_SENT = 2;
  FORM_ERROR = 3;

  contactForm: FormGroup;
  formStatus = this.FORM_UNSENT;

  constructor(private fb: FormBuilder,
              private contactService: ContactService) { }

  ngOnInit(): void {

    this.contactForm = this.fb.group({
      email : [
        '',
        Validators.compose([Validators.required, Validators.email])
      ],
      name: [
        '',
        Validators.required
      ],
      message : [
        '',
        Validators.required
      ]
    })
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.formStatus = this.FORM_SUBMITTED;

      this.contactService.sendContactForm(this.contactForm.value)
        .subscribe(
          res => this.formStatus = this.FORM_SENT,
          err => this.formStatus = this.FORM_ERROR
        );
    }
  }

  tryAgain() {
    this.formStatus = this.FORM_UNSENT;
  }


  /*
  switchState() {
    if (this.formStatus === 3) {
      this.setFormStatus(0)
    } else {
      this.setFormStatus(this.formStatus += 1)
    }
  }

   */

}
