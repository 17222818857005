import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../../config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }


  sendContactForm(values: any): Observable<boolean> | null {
    if (typeof values === 'object') {
      const body = {
        form: values,
        __mailsubject: 'Nová zpráva z kontaktního formuláře'
      };

      return this.http.post(config.urls.CONTACT_FORM, body, {
        observe: 'response',
        headers: {
          'X-Skip-Error-Handler-Interceptor': ''
        }
      })
        .pipe(
          map(res => res.status === 200)
        );
    }

    return null;
  }
}
