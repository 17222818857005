import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faq: FAQItem[] = [
    {
      question: "V jakém rozsahu jste schopni odstranit poškození kol?",
      answer: "Lorem ipsum dolor sit amet, " +
        "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." +
        " Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi \tut aliquip ex ea" +
        " commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore" +
        " eu fugiat nulla pariatur.",
      isOpened: false
    },
    {
      question: "Nabízíte možnost vyzvednutí vozu u mne doma?",
      answer: "Lorem ipsum dolor sit amet, " +
        "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." +
        " Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi \tut aliquip ex ea" +
        " commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore" +
        " eu fugiat nulla pariatur.",
      isOpened: false
    },
    {
      question: "Jak dlouho trvá oprava vozu?",
      answer: "Lorem ipsum dolor sit amet, " +
        "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." +
        " Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi \tut aliquip ex ea" +
        " commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore" +
        " eu fugiat nulla pariatur.",
      isOpened: false
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  toggleQuestionOpen(item: FAQItem) {
    item.isOpened = !item.isOpened
  }

}

export interface FAQItem {
  question: string,
  answer: string,
  isOpened: boolean
}
