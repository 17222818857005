<div class="footer-container">
  <div class="footer-wrapper container">
    <div class="contact-info">
      <h4>kontaktní údaje</h4>
      <p class="firm-name">Vracím Operák s.r.o.</p>
      <p><small>IČ: 09660291</small></p>
      <p><small>Na příkopě 1047/17, Staré Město</small></p>
      <p><small>110 00 Praha</small></p>
      <a href="https://www.google.com/maps/place/Na+P%C5%99%C3%ADkop%C4%9B,
      +110+00+Star%C3%A9+M%C4%9Bsto/@50.1777291,14.3697764,12z"
         target="_blank">ukázat na mapě</a>
      <p class="email"><small>info@vracimoperak.cz</small></p>
    </div>

    <div class="logo-container">
      <img src="assets/logo_VO_text_white.png" alt="logo">
    </div>
    <h3 class="copyright">2023 &#169; vracimoperak.cz</h3>

    <!--
    <div class="trade-groups">
      <h3>Kliment Group</h3>
      <h3>kWorks</h3>
      <h3>kAeroSpace</h3>
    </div>
    -->

    <div class="go-up">
      <a [routerLink]="['/']" [fragment]="'header'">
        <button><i class="far fa-chevron-double-up"></i></button>
      </a>
    </div>
  </div>
</div>
