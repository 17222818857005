<div class="contact-container container">
  <div class="heading">
    <h1>kontaktujte nás</h1>
  </div>
  <div class="line"></div>

  <div class="mail">
    <i class="fas fa-envelope"></i>
    <a href="mailto:">info@vracimoperak.cz</a>
  </div>
  <div class="phone">
    <i class="fas fa-phone-alt"></i>
    <a href="tel:">+420 608 775 775</a>
  </div>

<!--  <ng-container>-->
<!--    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">-->
<!--      <input type="email" formControlName="email" placeholder="Váš E-mail: " class="contact-info">-->
<!--      <input type="text" formControlName="name" placeholder="Vaše jméno: " class="contact-info">-->
<!--      <textarea formControlName="message" placeholder="Vaše zpráva: "></textarea>-->
<!--      <div class="send-section">-->
<!--        <div class="validator">-->
<!--          <p>4 + 12 =</p>-->
<!--          <input type="number">-->
<!--        </div>-->
<!--        <button type="submit" [disabled]="contactForm.invalid">Odeslat</button>-->
<!--      </div>-->
<!--    </form>-->
<!--  </ng-container>-->


</div>
