import { environment} from "../environments/environment";

export const baseUrl = environment.production ? 'https://vracimoperak.cz/admin' : 'http://localhost:8000/admin';

export const config = {
  apiKey: '',
  urls: {
    // ABOUT: `${baseUrl}/api/singletons/get/about`,
    CONTACT_FORM: `${baseUrl}/api/forms/submit/contactForm`
  },
  menu: [
    { title: 'O NÁS', url: '/', fragment: 'about' },
    { title: 'JAK VÁM POMŮŽEME', url: '/', fragment: 'how-we-help' },
    { title: 'KONTAKT', url: '/', fragment: 'contact' },
    // { title: 'REFERENCE', url: '/', fragment: 'references' },
    { title: 'FAQ', url: '/', fragment: 'faq' },
  ],
  title: 'VracimOperak.cz'
};
